import { Form, Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { Button, InputWithSelect } from "../../../../../../components";
import { useAppDispatch, useAppSelector } from "../../../../../../store";
import { updateOneStreet } from "../../../../../../store/slices/admin";
import appConfig from "../../../../../../util/appConfig";
import transformHexToRgba from "../../../../../../util/transformHexToRgba";
import Row from "./components/Row";
import style from "./style.module.scss";

interface IBlockLisForm {
  closeModalHandler: () => void;
  setLength: (num: number) => void;
}

interface IInitValues {
  strasseBezeichnung: string;
  blockedHouseNumbers: { from: string; to: string; id: string }[];
}

const BlockLisForm: FC<IBlockLisForm> = ({ closeModalHandler, setLength }) => {
  const dispatch = useAppDispatch();

  const { activeStreets, ignoredStreets } = useAppSelector(
    (state) => state.admin
  );

  const [blockedHouseNumbers, setBlockedHouseNumbers] = useState<
    { from: string; to: string; id: string }[]
  >([]);
  const [selectedStreet, setSelectedStreet] = useState("");
  const initialValues: IInitValues = {
    strasseBezeichnung: selectedStreet,
    blockedHouseNumbers: [...blockedHouseNumbers],
  };

  const addRow = () => {
    const arr = [
      ...blockedHouseNumbers,
      { from: "", to: "", id: Math.floor(Math.random() * 10000000).toString() },
    ];
    setBlockedHouseNumbers(arr);
  };

  const deleteRowHandler = (id: string) => {
    const arr = blockedHouseNumbers.filter((i) => i.id !== id);

    setBlockedHouseNumbers(arr);
  };

  const changeHandler = (val: string, field: string, idx: number) => {
    const temp = blockedHouseNumbers.map((el, id) => {
      if (id === idx) {
        let item = { ...el };
        (item as any)[field] = val;
        return item;
      }
      return el;
    });
    setBlockedHouseNumbers([...temp]);
  };

  useEffect(() => {
    const item = ignoredStreets.find(
      (el) => el.strasseBezeichnung === selectedStreet
    );
    if (item) {
      setBlockedHouseNumbers(
        item.blockedHomeNumbers.map((el) => {
          return { from: el.from, to: el.to, id: el._id };
        })
      );
    }
  }, [selectedStreet]);

  useEffect(() => {
    setLength(blockedHouseNumbers.length);
  }, [blockedHouseNumbers]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { resetForm }) => {
        const id = activeStreets
          .find((i) => i.strasseBezeichnung === values.strasseBezeichnung)
          ?.strasseNummer.toString()!;
        dispatch(
          updateOneStreet({
            id,
            ignore: values.blockedHouseNumbers.length ? "partially" : "ignore",
            blockedHomeNumbers: [
              ...values.blockedHouseNumbers.map((i) => {
                return { from: i.from, to: i.to };
              }),
            ],
          })
        );
        // dispatch(updateStreets({ id: [id], ignore: values.blockedHouseNumbers.length ? 'partially' : 'ignore' }));
        resetForm();
        closeModalHandler();
      }}
      enableReinitialize
    >
      {(props) => {
        return (
          <Form>
            <InputWithSelect
              type="string"
              name="strasseBezeichnung"
              options={activeStreets.map((i) => {
                return {
                  value: i.strasseBezeichnung,
                  label: i.strasseBezeichnung,
                };
              })}
              hasFullWidth
              arrowTopLocation={"50%"}
              changeHandler={(v) => setSelectedStreet(v)}
            />
            {blockedHouseNumbers.length ? (
              <div>
                {blockedHouseNumbers.map((i, idx) => {
                  return (
                    <Row
                      deleteHandler={() => {
                        deleteRowHandler(i.id.toString());
                        props.values.blockedHouseNumbers.splice(idx, 1);
                        props.setFieldValue(
                          "blockedHouseNumbers",
                          props.values.blockedHouseNumbers
                        );
                      }}
                      key={i.id}
                      idx={idx}
                      onChange={(val: string, field: string) => {
                        changeHandler(val, field, idx);
                      }}
                    />
                  );
                })}
              </div>
            ) : null}
            {props.values.strasseBezeichnung ? (
              <div className={style.addHouseNumber} onClick={addRow}>
                + Add blocked house numbers
              </div>
            ) : null}
            <div className={style.btnWrapper}>
              <Button
                text="Schließen"
                width="298"
                onClick={closeModalHandler}
                isOutlined={true}
                outlineColor={appConfig.mainColor}
                textColor={appConfig.mainColor}
              />
              <Button
                width="298"
                text="Speichern"
                backgroundColor={
                  props.values.strasseBezeichnung.length
                    ? appConfig.mainColor
                    : transformHexToRgba(appConfig.mainColor!, "0.4")
                }
                type="submit"
                disabled={!props.values.strasseBezeichnung.length}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BlockLisForm;
