import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setErrorMessage, setIsLoading, setIsToasterError } from "./app";
import API from "../../api/api";

export interface IStammdatenResponseBody {
  personendaten: {
    nachname: string;
    vorname: string;
    namezusatz: null | string;
    strasse: string;
    hausnr: string;
    plz: string;
    ort: string;
    land: null | string;
    postfach: null | string;
    telefon: null | string;
    telefax: null | string;
    email: null | string;
    debitornr: number;
  };
  zahlungsdaten: {
    zahlungsartkennung: string;
    iban: string;
    bic: string;
    bankname: string;
    mandatnr: string;
    kontoinhaber?: string;
  };
  objektdaten: {
    adressart: string;
    kassenzeichen: number;
    objekttyp: number;
    gemeindebez: string;
    bezirkbez: string;
    ortsteilbez: string;
    plz: string;
    strassebez: string;
    hausnr: string;
    datumvon: string | null;
    datumbis: null | string;
    zusatztext1: string;
  };
  einwohnerdaten: {
    personenzahl: number | null,
    serviceartkennung: string | null;
    anmeldungdatum: string | null;
  };
}

interface IStammdaten {
  user: {
    data: null | IStammdatenResponseBody;
    isDataLoading: boolean;
  };
}

const initialState: IStammdaten = {
  user: {
    data: null,
    isDataLoading: false,
  },
};

export const getUserData = createAsyncThunk(
  "stammdaten/user/getUserData",
  async (
    {
      gemeindeNr,
      objektId,
    }: {
      gemeindeNr: number;
      objektId: number;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setIsLoading(true));

      const data = await API.stammdaten.get.getUserData({
        gemeindeNr,
        objektId,
      });

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

const stammdatenSlice = createSlice({
  name: "stammdaten",
  initialState,
  reducers: {
    clearStammdatenData: (state) => {
      state.user.data = null;
      state.user.isDataLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserData.pending, (state) => {
      state.user.isDataLoading = true;
    });
    builder.addCase(getUserData.fulfilled, (state, action) => {
      if (action.payload) {
        state.user.data = action.payload;
      }
      state.user.isDataLoading = false;
    });
    builder.addCase(getUserData.rejected, (state) => {
      state.user.isDataLoading = false;
    });
  },
});

export const { clearStammdatenData } = stammdatenSlice.actions;

export const stammdatenReducer = stammdatenSlice.reducer;
